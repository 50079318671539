import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "text-field",
      "style": {
        "position": "relative"
      }
    }}>{`Text Field`}</h1>
    <p>{`O componente permite ao usuário inserir e interagir com entrada de dados. Normalmente encontrado em formulários, mas também está presente em modais, buscas ou cards.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Utilize `}<em parentName="li">{`placeholders`}</em>{` para dar dicas do conteúdo que deve ser inserido no campo.`}</li>
      <li parentName="ul">{`Campos obrigatórios devem ser sinalizados com um asterisco vermelho. Se todos os campos são obrigatórios não há a necessidade de apresentar o asterisco em todos eles, mas a obrigatoriedade deve ser indicada no topo da página.`}</li>
      <li parentName="ul">{`A validação dos campos deve ocorrer em tempo real, facilitando o processo do usuário na hora de completar formulários.`}</li>
    </ul>
    <h2 {...{
      "id": "tipos-de-text-input",
      "style": {
        "position": "relative"
      }
    }}>{`Tipos de Text Input`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Text field</th>
      <td>Utilizado quando o usuário precisa inserir apenas uma linha de texto.</td>
    </tr>
    <tr>
      <th>Text area</th>
      <td>Utilizado quando o usuário precisa inserir mais de uma sentença.</td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "text-field-1",
      "style": {
        "position": "relative"
      }
    }}>{`Text Field`}</h2>
    <Demo src='pages/components/text-field/TextField' mdxType="Demo" />
    <h2 {...{
      "id": "text-area",
      "style": {
        "position": "relative"
      }
    }}>{`Text Area`}</h2>
    <Demo src='pages/components/text-field/TextArea' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      